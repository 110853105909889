import { useState, useEffect, useCallback, useRef } from 'react';
import { Howl } from 'howler';
import { useTranslation } from "react-i18next";

// Import sound files
import beepSfx from '../assets/audio/beep.mp3';
import workSfx from '../assets/audio/work.mp3';
import workDESfx from '../assets/audio/work-1-de.mp3';
import workFRSfx from '../assets/audio/work-fr.mp3';
import workDEContinueSfx from '../assets/audio/work-2-de.mp3';
import restSfx from '../assets/audio/rest.mp3';
import restDESfx from '../assets/audio/rest-de.mp3';
import restFRSfx from '../assets/audio/rest-fr.mp3';
import getReadySfx from '../assets/audio/get-ready.mp3';
import getReadyDESfx from '../assets/audio/get-ready-de.mp3';
import getReadyFRSfx from '../assets/audio/get-ready-fr.mp3';
import nextExerciseSfx from '../assets/audio/next-exercise.mp3';
import nextExerciseDESfx from '../assets/audio/next-exercise-de.mp3';
import nextExerciseFRSfx from '../assets/audio/next-exercise-fr.mp3';
import workoutCompleteSfx from '../assets/audio/workout_complete.mp3';
import workoutCompleteDESfx from '../assets/audio/workout_complete-de.mp3';
import workoutCompleteFRSfx from '../assets/audio/workout_complete-fr.mp3';

const useSfx = () => {
    const { i18n } = useTranslation();
    const soundsRef = useRef({});
    const [isReady, setIsReady] = useState(true);
    const initializationTimer = useRef(null);

    // Get the correct sound files based on language
    const getSoundFiles = useCallback(() => {
        if (i18n.language === 'de') {
            return {
                work: workDESfx,
                rest: restDESfx,
                getReady: getReadyDESfx,
                nextExercise: nextExerciseDESfx,
                workoutComplete: workoutCompleteDESfx,
                workContinue: workDEContinueSfx
            };
        } else if (i18n.language === 'fr') {
            return {
                work: workFRSfx,
                rest: restFRSfx,
                getReady: getReadyFRSfx,
                nextExercise: nextExerciseFRSfx,
                workoutComplete: workoutCompleteFRSfx,
                workContinue: workDEContinueSfx
            };
        }
        return {
            work: workSfx,
            rest: restSfx,
            getReady: getReadySfx,
            nextExercise: nextExerciseSfx,
            workoutComplete: workoutCompleteSfx,
            workContinue: workDEContinueSfx
        };
    }, [i18n.language]);

    // Initialize sounds
    const initializeSounds = useCallback(() => {
        setIsReady(false);

        // Clear any existing initialization timer
        if (initializationTimer.current) {
            clearTimeout(initializationTimer.current);
        }

        // Unload existing sounds
        Object.values(soundsRef.current).forEach(sound => {
            if (sound && typeof sound.unload === 'function') {
                sound.stop();
                sound.unload();
            }
        });

        const soundFiles = getSoundFiles();
        const howlConfig = {
            preload: true,
            html5: false,
            volume: 1.0,
            format: ['mp3'],
            onload: () => {
                setIsReady(true);
            },
            onloaderror: (id, error) => {
                console.warn('Sound loading error:', error);
                setIsReady(true); // Still set ready to allow retries
            }
        };

        // Create new Howl instances
        const newSounds = {
            beep: new Howl({ ...howlConfig, src: [beepSfx] }),
            work: new Howl({ ...howlConfig, src: [soundFiles.work] }),
            workContinue: new Howl({ ...howlConfig, src: [soundFiles.workContinue] }),
            rest: new Howl({ ...howlConfig, src: [soundFiles.rest] }),
            getReady: new Howl({ ...howlConfig, src: [soundFiles.getReady] }),
            nextExercise: new Howl({ ...howlConfig, src: [soundFiles.nextExercise] }),
            workoutComplete: new Howl({ ...howlConfig, src: [soundFiles.workoutComplete] })
        };

        soundsRef.current = newSounds;

        // Set a small delay before allowing playback
        initializationTimer.current = setTimeout(() => {
            setIsReady(true);
        }, 300);
    }, [getSoundFiles]);

    // Reinitialize sounds when language changes
    useEffect(() => {
        initializeSounds();

        return () => {
            if (initializationTimer.current) {
                clearTimeout(initializationTimer.current);
            }
            Object.values(soundsRef.current).forEach(sound => {
                if (sound && typeof sound.unload === 'function') {
                    sound.stop();
                    sound.unload();
                }
            });
        };
    }, [i18n.language, initializeSounds]);

    // Create play function
    const createPlayFunction = useCallback((soundKey) => {
        return () => {
            if (!isReady) {
                console.warn('Audio system not ready yet');
                return;
            }

            const sound = soundsRef.current[soundKey];
            if (sound) {
                try {
                    sound.stop(); // Stop any playing instances
                    sound.play();
                } catch (error) {
                    console.warn(`Error playing ${soundKey}:`, error);
                    // Attempt to reinitialize on error
                    initializeSounds();
                }
            }
        };
    }, [isReady, initializeSounds]);

    // Public interface
    const playFunctions = {
        playBeep: useCallback(() => createPlayFunction('beep')(), [createPlayFunction]),
        playWork: useCallback(() => createPlayFunction('work')(), [createPlayFunction]),
        playWorkContinue: useCallback(() => createPlayFunction('workContinue')(), [createPlayFunction]),
        playRest: useCallback(() => createPlayFunction('rest')(), [createPlayFunction]),
        playGetReady: useCallback(() => createPlayFunction('getReady')(), [createPlayFunction]),
        playNextExercise: useCallback(() => createPlayFunction('nextExercise')(), [createPlayFunction]),
        playWorkoutComplete: useCallback(() => createPlayFunction('workoutComplete')(), [createPlayFunction])
    };

    return {
        ...playFunctions,
        isReady
    };
};

export default useSfx;