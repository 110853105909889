import React, {useState, useEffect, useContext} from 'react';
import {formatSeconds} from "../helpers";
import checkIcon from '../assets/images/check_icon.svg';
import crossIcon from '../assets/images/cross_icon.svg';
import infoIcon from '..//assets/images/info_icon.svg'
import {Link, useNavigate} from "react-router-dom";
import {Context} from "../context";
import {useTranslation} from "react-i18next";
import WeekNavigation from "./WeekNavigation";

const WorkoutInfo = ({workout, workoutTimer, startWorkoutTimer, activeMike5Workout, setSelectedExercises}) => {
    const workoutTitle = workout.appTitle || workout.title || workout.mike5Component;
    const workoutDescription = workout.mike5Component && workout.mike5Component.length
        ? activeMike5Workout?.description?.[workout.mike5Component]
        : workout.description;
    const navigate = useNavigate();
    const {setExercises} = useContext(Context);
    const { t} = useTranslation();

    useEffect(() => {
        setExercises(workout.exercises.map(exercise =>
            exercise.isSelected === undefined
                ? { ...exercise, isSelected: true }
                : exercise));
    }, [workout.mike5Component]);

    const toggleSelected = (index) => {
        const updatedExercises = workout.exercises.map((exercise, i) =>
            i === index ? { ...exercise, isSelected: exercise.isSelected === false } : exercise
        );

        // At least one exercise needs to be selected.
        if (updatedExercises.filter(exercise => exercise.isSelected).length > 0) {
            setExercises(updatedExercises);
        }
    };

    return (
        <div className="workout-info">
            <div className="workout-info__navigation">
                {workout.mike5Component &&
                    <>
                        <h1 className={`workout-info__workout-title ${workout.mike5Component !== 'big5' ? 'inactive' : ''}`}
                            onClick={() => navigate('/workout/mike5/big5')}>
                            Big 5
                        </h1>
                        <h1 className={`workout-info__workout-title ${workout.mike5Component !== 'assistance' ? 'inactive' : ''}`}
                            onClick={() => navigate('/workout/mike5/assistance')}>
                            Assistance
                        </h1>
                        <h1 className={`workout-info__workout-title ${workout.mike5Component !== 'core' ? 'inactive' : ''}`}
                            onClick={() => navigate('/workout/mike5/core')}>
                            Core
                        </h1>
                    </>
                }

                {!workout.mike5Component &&
                    <h1 className="workout-info__workout-title">
                        {workoutTitle}
                    </h1>
                }

            </div>
            {workoutDescription && workoutDescription.length > 0 &&
                <div className="workout-info__description" dangerouslySetInnerHTML={{__html: workoutDescription}}/>
            }

            <div className="workout-info__facts">
                <h2 className="workout-info__subtitle">{t('informations')}</h2>
                <div className="workout-info__facts-wrapper">
                    {!workoutTimer.hasContinuosTimer && workout.mike5Component !== 'core' &&
                        <>
                            <div className="workout-info__fact">
                                <div className="workout-info__fact-title">{t('interval')}</div>
                                <div
                                    className="workout-info__fact-content">{workoutTimer.setsCount} {t('sets')}, {workout.interval.workTime}s
                                    {t('workTime')}, {workout.interval.restTime}s {t('restTime')}

                                    {workout.interval.link &&
                                        <>
                                            <br/>
                                            <Link to={workout.interval.link} className="workout-info__fact-link"
                                                  target="_blank" rel="noopener">
                                                <img src={infoIcon} height="15"/> <span>{t('linkTextInterval')}</span>
                                            </Link>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="workout-info__fact">
                                <div className="workout-info__fact-title">{t('timeUnderTension')}</div>
                                <div
                                    className="workout-info__fact-content">{formatSeconds(((workout.interval.workTime * workoutTimer.setsCount)) * workoutTimer.exercisesCount)} {t('minutes')}
                                </div>
                            </div>
                            <div className="workout-info__fact">
                                <div className="workout-info__fact-title">{t('totalTime')}</div>
                                <div
                                    className="workout-info__fact-content">{formatSeconds(((workout.interval.workTime * workoutTimer.setsCount) + (workout.interval.restTime * workoutTimer.setsCount) - workout.interval.restTime) * workoutTimer.exercisesCount)} {t('minutes')}
                                </div>
                            </div>
                        </>
                    }
                    {(workoutTimer.hasContinuosTimer || workout.mike5Component === 'core') &&
                        <div className="workout-info__fact">
                            <div className="workout-info__fact-title">{t('ablauf')}</div>
                            <div
                                className="workout-info__fact-content">{t('infoCycle')}
                            </div>
                        </div>
                    }

                </div>
            </div>

            { (workout.workoutType === 'day-based' || workout.mike5Component ) &&
                <>
                    <h2 className="workout-info__subtitle">{t('weekProgress')}</h2>
                    <WeekNavigation type='workout' workout={workout} mike5Component={workout.mike5Component}/>
                </>
            }

            <div className="workout-info__exercises">
                <h2 className="workout-info__subtitle">{(workout.mike5Component && workout.mike5Component === 'big5') ? t('todaysExercise') : `${t('exercises')} (${workoutTimer.exercisesCount})`}</h2>
                <div className="workout-info__exercises-wrapper">
                    {workout.exercises.map((exercise, index) => (
                        <div className={`workout-info__exercise ${exercise.isSelected !== false ? 'is-selected' : ''}`}>
                            <Link className="workout-info__exercise-image" to={exercise.link}
                                  target="_blank"
                                  rel="noopener">
                                <img src={exercise.imageLink} alt="" width="64" height="64"/>
                            </Link>
                            <Link className="workout-info__exercise-info" to={exercise.link}
                                  target="_blank"
                                  rel="noopener">
                                <div className="workout-info__exercise-title" dangerouslySetInnerHTML={{__html: exercise.title}}></div>
                                <div
                                    className="workout-info__exercise-meta">{t('muscles')}: {exercise.primaryMuscle}/{exercise.secondaryMuscle}</div>
                            </Link>
                            {workout.mike5Component !== 'big5' &&
                                <button
                                    className="workout-info__exercise-selector workout-info__exercise-selector"
                                    onClick={() => toggleSelected(index)}
                                >
                                    <img src={exercise.isSelected !== false ? checkIcon : crossIcon} alt="" height="24"
                                         width="24"/>
                                </button>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <button className="btn btn--large workout-info__workout-start"
                    onClick={startWorkoutTimer}>{t('startWorkout')}</button>
        </div>
    );
};

export default WorkoutInfo;